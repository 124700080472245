import React from 'react'
import dnRegalia from "./logos/DN_Regalia_Logo.png"
import ambujaNeotia from "./logos/ambujaNeotia.png"
import avaniRiverside from "./logos/Avani_Riverside_Logo.png"
import "./AssociationLogos.css"

const AssociaitonLogos = () => {
  return (
    <div className='AssociationsLogosWrapper'>
        <div className="venue-associations">
            <div className="venueAssociationsText">Venue Associations</div>
            <div className="venueLogos">
            <div className="logoAmbujaNeotia"><img className="ambujaNeotia" src={ambujaNeotia} alt="" /></div>
              <div className="logoDNRegalia"><img className="dnregalia" src={dnRegalia} alt="" /></div>
              
              <div className="logoAvaniRiverside"><img className="avaniRiverside" src={avaniRiverside} alt="" /></div>
            </div>
          </div>
    </div>
  )
}

export default AssociaitonLogos