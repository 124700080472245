import React, { useState, useEffect } from "react";
// import Modal_Registration from "./Modal_Registration";
import "./EventDashboard.css";
import ModalAbout from "./Modal_About";
import ModalRules from "./Modal_Rules";
import ModalRegistrationPage from "./Modal_Registration_page";
import { useParams } from "react-router-dom";

// import rope from "./images/Asset_8.png";
// import rope2 from "./images/Asset 9.png";
// import rope3 from "./images/Asset 10.png";
import about from "./images/About_Banner_Final.png";
import aboutoff from "./images/Aboutoff.png";
import subs from "./images/ResultsGlow.png";
import subsoff from "./images/ResultsWithoutGlow.png";
import reg from "./images/Registration_Banner_Final.png";
import regoff from "./images/Registeroff_1.png";
import rules from "./images/Rules_Banner_Final.png";
import rulesoff from "./images/Rulesoff_1.png";
// import rules2 from "./images/Venue.png";
import BackButton from "../BackButton/BackButton";
// import Navbar from "../Navbar/Navbar";
// import { rule } from "postcss";

function EventDashboard(props) {
  let params = useParams();
  const { city, event } = params;
  const changeEvent = event.split("_").join(" ");
  console.log(city);
  useEffect(() => {
    // localStorage.clear();
    // localStorage.setItem("loginStat", true);
    // localStorage.setItem("regStat", true);
  }, []);
  const toggleAboutModal = () => {
    setModalAboutOpen(true);
    setModalRulesOpen(false);
    setModalRegistrationOpen(false);
  };
  const toggleRulesModal = () => {
    setModalAboutOpen(false);
    setModalRulesOpen(true);
    setModalRegistrationOpen(false);
  };
  const toggleRegistrationModal = () => {
    setModalAboutOpen(false);
    setModalRulesOpen(false);
    setModalRegistrationOpen(true);
  };
  const [modalAboutOpen, setModalAboutOpen] = useState(false);
  const [modalRulesOpen, setModalRulesOpen] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [modalRegistrationOpen, setModalRegistrationOpen] = useState(false);
  const [isSub, setisSub] = useState(false);
  const [isHoveredAbt,setIsHoveredAbt]=useState(false);
  const [isHoveredRul,setIsHoveredRul]=useState(false);
  const [isHoveredSub,setIsHoveredSub]=useState(false);
  const [isHoveredReg,setIsHoveredReg]=useState(false);
  // const [name, setname] = useState();
  // const [city, setcity] = useState();

  useEffect(() => {
      if (window.innerWidth < 930) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
  }, [window.innerWidth]);
  // console.log(mobileView);
  return (
    <>
      {mobileView === true ? (
        <div className="EventDashboard-mob">
          <BackButton />
          <div className="evntDashmob">
            <div className="btn-body">
              <button
                className="about-buttons"
                onClick={() => {
                  toggleAboutModal();
                }}
              >
                About
              </button>
              <button
                className="sub-buttons"
                onClick={() => {
                  // toggleRegistrationModal();
                  setModalRegistrationOpen(true);
                  setisSub(true);
                }}
              >
                Results
              </button>
              <button
                className="rules-buttons"
                onClick={() => {
                  toggleRulesModal();
                }}
              >
                Rules
              </button>
              <button
                className="reg-buttons"
                onClick={() => {
                  toggleRegistrationModal();
                  setisSub(false);
                }}
              >
                Register
              </button>
            </div>
            {modalAboutOpen && (
              <ModalAbout
                setOpenModal={setModalAboutOpen}
                name={changeEvent}
              />
            )}
            {modalRulesOpen && (
              <ModalRules
                setOpenModal={setModalRulesOpen}
                name={changeEvent}
              />
            )}
            {modalRegistrationOpen && (
              <ModalRegistrationPage
                setOpenModal={setModalRegistrationOpen}
                showToast={props.showToast}
                name={changeEvent}
                isSub={isSub}
                evcity={city}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="EventDashboard">
          <BackButton />
          <div className="evntDash">
            <div className="evt-body">
              <img
                className="evt-abt"
                src={!isHoveredAbt? about: aboutoff}
                alt="About Button"
                onClick={() => {
                  toggleAboutModal();
                }}
                onMouseEnter={() => setIsHoveredAbt(true)}
                onMouseLeave={() => setIsHoveredAbt(false)}
              />
              <img
                className="evt-reg"
                src={!isHoveredReg? reg: regoff}
                alt="Registration Button"
                onClick={() => {
                  toggleRegistrationModal();
                  setisSub(false);
                }}
                onMouseEnter={() => setIsHoveredReg(true)}
                onMouseLeave={() => setIsHoveredReg(false)}
              />
              <img
                className="evt-rul"
                src={!isHoveredRul? rules: rulesoff}
                alt="Rules Button"
                onClick={() => {
                  // toggleRegistrationModal();
                  // setisSub(true);
                  toggleRulesModal();
                }}
                onMouseEnter={() => setIsHoveredRul(true)}
                onMouseLeave={() => setIsHoveredRul(false)}
              />
              <img
                className="evt-sub"
                src={!isHoveredSub? subs: subsoff}
                alt="Submission Button"
                onClick={() => {
                  toggleRegistrationModal();
                  setisSub(true);
                  
                }}
                onMouseEnter={() => setIsHoveredSub(true)}
                onMouseLeave={() => setIsHoveredSub(false)}
              />
            </div>
            {modalAboutOpen && (
              <ModalAbout
                setOpenModal={setModalAboutOpen}
                name={changeEvent}
              />
            )}
            {modalRulesOpen && (
              <ModalRules
                setOpenModal={setModalRulesOpen}
                name={changeEvent}
              />
            )}
            {modalRegistrationOpen && (
              <ModalRegistrationPage
                setOpenModal={setModalRegistrationOpen}
                showToast={props.showToast}
                name={changeEvent}
                isSub={isSub}
                evcity={city}
              />
            )}
          </div>
        </div>
      )}

      {/* <h1 className="event-name-heading">{props.name}</h1> */}
    </>
  );
}

export default EventDashboard;
