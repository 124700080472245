import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import "./FlipPage.css";
// import { Link } from "react-router-dom";
import Kolkata from "./CityIcons/kolkata.png";
// import Chennai from "./CityIcons/chennai.png";
import Ahemdabad from "./CityIcons/Ahmedabad.png";
import Bhubaneshwar from "./CityIcons/Bhubaneswar.png";
import Chandigarh from "./CityIcons/chandigarh.png";
import Delhi from "./CityIcons/Delhi.png";
import Guwahati from "./CityIcons/Guwahati.png";
import Hyderabad from "./CityIcons/Hyderabad.png";
import Jaipur from "./CityIcons/jaipur.png";
import Indore from "./CityIcons/Indore.png";
import Lucknow from "./CityIcons/Lucknow.png";
import Mumbai from "./CityIcons/Mumbai.png";
import Nagpur from "./CityIcons/Nagpur.png";
import Patna from "./CityIcons/patna.png";
import Pune from "./CityIcons/pune.png";
import Vishakapatnam from "./CityIcons/Vishakapatnam.png";
import Raipur from "./CityIcons/Bhopal2.png";
import Ranchi from "./CityIcons/Ranchi.png";
import line from "./BookImges/line_nwp.png";
import EventContent from "./EventContent";
import EventContent2 from "./EventContent2";
import BackButton from "../BackButton/BackButton";
import { UserContext } from "../../UserContext";
import { useContext } from "react";
import bookbg from "./bookbgg.png";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className={`demoPage-${props.number} demoPage`} ref={ref}>
      <div className={`propsChildren`}>{props.children}</div>
    </div>
  );
});

function MyBook(props) {
  // var myIndex1 = 0;
  // var myIndex2 = 0;
  // var myIndex3 = 0;
  // var myIndex4 = 0;
  // var myIndex5 = 0;
  // var myIndex6 = 0;
  // const carousel1 = () => {
  //   let i1;
  //   let x1 = document.getElementsByClassName("galimg");
  //   for (i1 = 0; i1 < x1.length; i1++) {
  //     x1[i1].style.opacity = "0";
  //   }
  //   myIndex1++;
  //   if (myIndex1 > x1.length) {
  //     myIndex1 = 1;
  //   }
  //   x1[myIndex1 - 1].style.opacity = "1";
  // };
  // const carousel2 = () => {
  //   let i;
  //   let x = document.getElementsByClassName("galimg2");
  //   for (i = 0; i < x.length; i++) {
  //     x[i].style.opacity = "0";
  //   }
  //   myIndex2++;
  //   if (myIndex2 > x.length) {
  //     myIndex2 = 1;
  //   }
  //   x[myIndex2 - 1].style.opacity = "1";
  // };
  // const carousel3 = () => {
  //   let i;
  //   let x = document.getElementsByClassName("galimg3");
  //   for (i = 0; i < x.length; i++) {
  //     x[i].style.opacity = "0";
  //   }
  //   myIndex3++;
  //   if (myIndex3 > x.length) {
  //     myIndex3 = 1;
  //   }
  //   x[myIndex3 - 1].style.opacity = "1";
  // };
  // const carousel4 = () => {
  //   let i;
  //   let x = document.getElementsByClassName("galimg4");
  //   for (i = 0; i < x.length; i++) {
  //     x[i].style.opacity = "0";
  //   }
  //   myIndex4++;
  //   if (myIndex4 > x.length) {
  //     myIndex4 = 1;
  //   }
  //   x[myIndex4 - 1].style.opacity = "1";
  // };
  // const carousel5 = () => {
  //   let i;
  //   let x = document.getElementsByClassName("galimg5");
  //   for (i = 0; i < x.length; i++) {
  //     x[i].style.opacity = "0";
  //   }
  //   myIndex5++;
  //   if (myIndex5 > x.length) {
  //     myIndex5 = 1;
  //   }
  //   x[myIndex5 - 1].style.opacity = "1";
  // };
  // const carousel6 = () => {
  //   let i;
  //   let x = document.getElementsByClassName("galimg6");
  //   for (i = 0; i < x.length; i++) {
  //     x[i].style.opacity = "0";
  //   }
  //   myIndex6++;
  //   if (myIndex6 > x.length) {
  //     myIndex6 = 1;
  //   }
  //   x[myIndex6 - 1].style.opacity = "1";
  // };

  const bookstyle = `
  .demoPage-1 {background-image: url(${props.img6.src});}
  .demoPage-2{background-image: url(${props.img4.src});}
  .demoPage-3{
    background-image: url(${props.img2.src});}
    .demoPage-4,
.demoPage-6,
.demoPage-8,
.demoPage-10,
.demoPage-12,
.demoPage-14,
.demoPage-16,
.demoPage-18,
.demoPage-20,
.demoPage-22,
.demoPage-24,
.demoPage-26,
.demoPage-28,
.demoPage-30,
.demoPage-32,
.demoPage-34,
.demoPage-36,
.demoPage-38 {
  background-image: url(${props.img4.src});}
  .demoPage-5,
.demoPage-7,
.demoPage-9,
.demoPage-11,
.demoPage-13,
.demoPage-15,
.demoPage-17,
.demoPage-19,
.demoPage-21,
.demoPage-23,
.demoPage-25,
.demoPage-27,
.demoPage-29,
.demoPage-31,
.demoPage-33,
.demoPage-35,
.demoPage-37,
.demoPage-39 {
  background-image: url(${props.img2.src});
}
.eventbookout{background-image: url(${bookbg});}
  `;

  // const pageFlip = new pageFlip(document.getElementById('book'),{
  //   width:400,
  //   height:550
  // });
  // useEffect(()=>{
  //   Fliptoindpage(book.current.pageFlip()){
  //     book.current.pageFlip().on("flip", (e) => {
  //       setSmthng(e.data);
  //     });
  //   }
  // },[])
  // const [disClick, setDisClick] = useState(true);

  // const [bkcbtn, setBkcbtn] = useState(false);
  // const toggleBkctn = () => {
  //   setBkcbtn(!bkcbtn);
  // };
  // let allEvents = [];
  const { smthng, setSmthng } = useContext(UserContext);
  const [cntrl, setCntrl] = useState(0);
  const book = useRef();
  // const refOne = useRef(null);
  // const [coverPageClass, setCoverPageClass] = useState(
  //   "event-page event-page-cover"
  // );

  window.onclick = (e) => {
    if (e.srcElement.id === "book") {
      book.current.pageFlip().flip(0);
    }
    window.ondblclick = (e) => {
      if (e.srcElement.className !== "demoPage-1") {
        if (book.current.pageFlip().getCurrentPageIndex() === 0) {
          book.current.pageFlip().flipNext();
        }
      }
    };
  };
  useEffect(() => {
    setTimeout(() => {
      book.current.pageFlip().flip(smthng);
    }, 1500);
    setInterval(() => {
      // carousel1();
      // setTimeout(() => {
      //   carousel2();
      // }, 1270);
      // setTimeout(() => {
      //   carousel3();
      // }, 1500);
      // setTimeout(() => {
      //   carousel4();
      // }, 1200);
      // setTimeout(() => {
      //   carousel5();
      // }, 1500);
      // setTimeout(() => {
      //   carousel6();
      // }, 400);
    }, 3500);
  }, []);
  return (
    <>
      <BackButton togglebackclick={props.togglebackClickPage} />
      <style>{bookstyle}</style>
      <div className="book" id="book">
        <HTMLFlipBook
          height={550}
          width={400}
          showCover={true}
          ref={book}
          maxShadowOpacity={0.4}
          disableFlipByClick={true}
        >
          <Page
            number="1"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div style={{ display: "none" }}>
              {setTimeout(() => {
                book.current.pageFlip().on("flip", (e) => {
                  if (e.data === 1) {
                    setCntrl(1);
                  } else if (e.data === 3) {
                    setCntrl(3);
                  } else {
                    setCntrl(0);
                  }
                });
              }, 1500)}
            </div>
          </Page>
          {/* <Page number="2" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <figure className="Gallery-layout">
            <div className="BookGalleryContAll BookGalleryCont1">
            <img className="borderAboveAll borderAbove1" src={border_above} alt=" "/>
            <div className="gallery-images galleryimg1">
              
              <img src={Galleryimg1} class="galimg" alt="img1"/>
              <img src={Galleryimg2} class="galimg" alt="img2"/>
              <img src={Galleryimg3} class="galimg" alt="img3"/>
              <img src={Galleryimg4} class="galimg" alt="img4"/>
              <img src={Galleryimg5} class="galimg" alt="img5"/>
              <img src={Galleryimg6} class="galimg" alt="img6"/>
            </div>
            </div>
            <div className="BookGalleryContAll BookGalleryCont2">
            <img className="borderAboveAll borderAbove2" src={border_above} alt=" "/>
            <div className="gallery-images galleryimg2">
              <img src={Galleryimg8} class="galimg2" alt="img1"/>
              <img src={Galleryimg9} class="galimg2" alt="img2"/>
              <img src={Galleryimg10} class="galimg2" alt="img3"/>
              <img src={Galleryimg11} class="galimg2" alt="img4"/>
              <img src={Galleryimg12} class="galimg2" alt="img5"/>
              <img src={Galleryimg13} class="galimg2" alt="img6"/>
            </div>
            </div>
            <div className="BookGalleryContAll BookGalleryCont3">
            <img className="borderAboveAll borderAbove3" src={border_above} alt=" "/>
            <div className="gallery-images galleryimg3">
              <img src={Galleryimg15} class="galimg3" alt="img1"/>
              <img src={Galleryimg16} class="galimg3" alt="img2"/>
              <img src={Galleryimg17} class="galimg3" alt="img3"/>
              <img src={Galleryimg18} class="galimg3" alt="img4"/>
              <img src={Galleryimg19} class="galimg3" alt="img5"/>
              <img src={Galleryimg20} class="galimg3" alt="img6"/>
            </div>
            </div>
          </figure>
        </Page>
        <Page number="3" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <figure className="Gallery-layout">
            <div className="BookGalleryContAll BookGalleryCont7">
              <img className="borderAboveAll borderAbove7" src={border_above} alt=" "/>
            <div className="gallery-images galleryimg7">
              <img src={Galleryimg22} class="galimg4" alt="img1"/>
              <img src={Galleryimg23} class="galimg4" alt="img2"/>
              <img src={Galleryimg24} class="galimg4" alt="img3"/>
              <img src={Galleryimg25} class="galimg4" alt="img4"/>
              <img src={Galleryimg26} class="galimg4" alt="img5"/>
              <img src={Galleryimg27} class="galimg4" alt="img6"/>
            </div>
            </div>
            <div className="BookGalleryContAll BookGalleryCont8">
            <img className="borderAboveAll borderAbove8" src={border_above} alt=" "/>
            <div
              className="gallery-images galleryimg8"
            >
              <img src={Galleryimg29} class="galimg5" alt="img1"/>
              <img src={Galleryimg30} class="galimg5" alt="img2"/>
              <img src={Galleryimg31} class="galimg5" alt="img3"/>
              <img src={Galleryimg32} class="galimg5" alt="img4"/>
              <img src={Galleryimg33} class="galimg5" alt="img5"/>
              <img src={Galleryimg34} class="galimg5" alt="img6"/>
            </div>
            </div>
            <div className="BookGalleryContAll BookGalleryCont9">
              <img className="borderAboveAll borderAbove9" src={border_above} alt=" "/>
              <div className="gallery-images galleryimg9">
                <img src={Galleryimg36} class="galimg6" alt="img1"/>
                <img src={Galleryimg37} class="galimg6" alt="img2"/>
                <img src={Galleryimg38} class="galimg6" alt="img3"/>
                <img src={Galleryimg40} class="galimg6" alt="img5"/>
                <img src={Galleryimg41} class="galimg6" alt="img6"/>
                <img src={Galleryimg42} class="galimg6" alt="img7"/>
              </div>
            </div>
          </figure>
        </Page> */}
          <Page
            number="2"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="event-book-links">
              <button
                onClick={() => {
                  book.current.pageFlip().flip(4);
                }}
              >
                <div className="EVENTcITY">Raipur</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(6);
                }}
              >
                <div className="EVENTcITY">Ranchi</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(8);
                }}
              >
                <div className="EVENTcITY">Kolkata</div>
              </button>
              {/* <button
                onClick={() => {
                  book.current.pageFlip().flip(10);
                }}
              >
                <div className="EVENTcITY">Delhi</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(12);
                }}
              >
                <div className="EVENTcITY">Hyderabad</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(14);
                }}
              >
                <div className="EVENTcITY">Indore</div>
              </button> */}
            </div>
          </Page>
          <Page
            number="3"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="event-book-links">
              <button
                onClick={() => {
                  book.current.pageFlip().flip(10);
                }}
              >
                <div className="EVENTcITY">Bhubaneswar</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(12);
                }}
              >
                <div className="EVENTcITY">Guwahati</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(14);
                }}
              >
                <div className="EVENTcITY">Patna</div>
              </button>
              {/* <button
                onClick={() => {
                  book.current.pageFlip().flip(22);
                }}
              >
                <div className="EVENTcITY">Nagpur</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(24);
                }}
              >
                <div className="EVENTcITY">Pune</div>
              </button>
              <button
                onClick={() => {
                  book.current.pageFlip().flip(26);
                }}
              >
                <div className="EVENTcITY">Visakhapatnam</div>
              </button> */}
            </div>
          </Page>
          <Page
            number="4"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Ranchi}
                  className="EventBookCityNameImage"
                  alt="error"
                  style={{ transform: "translateX(-29px)" }}
                />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Ranchi</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                city={"Ranchi"}
                EventClicking={props.EventClicking}
                pgn={4}
              />
            </div>
          </Page>
          <Page
            number="5"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Ranchi"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={5}
              />
            </div>
          </Page>

          <Page
            number="6"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Kolkata}
                  className="EventBookCityNameImage"
                  alt="error"
                />{" "}
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Kolkata</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                city={"Kolkata"}
                EventClicking={props.EventClicking}
                pgn={6}
              />
            </div>
          </Page>
          <Page
            number="7"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Kolkata"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={7}
              />
            </div>
          </Page>

          <Page number="8" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <div className="EventCityIcon iconcity">
            <div className="iconCityName">
              <img src={Bhubaneshwar} className="EventBookCityNameImage EventBookCityNameImageBhub" alt="error" />
              <div className="EventBookCityNameHeading">
                <img src={line} className="EventBookCityNameLine" alt=" " />
                <div className="EventBookCityName">Bhubaneswar</div>
                <img src={line} className="EventBookCityNameLine" alt=" " />
              </div>
            </div>
          </div>
          <div>
            <EventContent
              numEvents={12}
              EventClicking={props.EventClicking}
              city={"Bhubaneshwar"}
              pgn={8}
            />
          </div>
        </Page>
        <Page number="9" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <div>
            <EventContent2
              city={"Bhubaneshwar"}
              numEvents={12}
              EventClicking={props.EventClicking}
              pgn={9}
            />
          </div>
        </Page>

          {/* <Page
            number="8"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Chandigarh}
                  className="EventBookCityNameImage"
                  alt="error"
                  style={{ transform: "translateX(-29px)" }}
                />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Chandigarh</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Chandigarh"}
                pgn={8}
              />
            </div>
          </Page>
          <Page
            number="9"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Chandigarh"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={9}
              />
            </div>
          </Page> */}

          {/* <Page number="10" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <div className="EventCityIcon iconcity">
            <div className="iconCityName">
              <img
                src={Chennai}
                alt="error"
                className="EventBookCityNameImage"
              />
              <div className="EventBookCityNameHeading">
                <img src={line} className="EventBookCityNameLine" alt=" " />
                <div className="EventBookCityName">Chennai</div>
                <img src={line} className="EventBookCityNameLine" alt=" " />
              </div>
            </div>
          </div>
          <div>
            <EventContent
              numEvents={11}
              EventClicking={props.EventClicking}
              city={"Chennai"}
              pgn={14}
            />
          </div>
        </Page>
        <Page number="15" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <div>
            <EventContent2
              city={"Chennai"}
              numEvents={11}
              EventClicking={props.EventClicking}
              pgn={15}
            />
          </div>
        </Page> */}

          {/* <Page
            number="10"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Delhi}
                  alt="error"
                  className="EventBookCityNameImageDelh"
                />{" "}
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Delhi</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <EventContent
              numEvents={12}
              pgn={10}
              EventClicking={props.EventClicking}
              city={"Delhi"}
            />
          </Page>
          <Page
            number="11"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Delhi"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={11}
              />
            </div>
          </Page> */}

          <Page number="10" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <div className="EventCityIcon iconcity">
            <div className="iconCityName">
              <img
                src={Guwahati}
                alt="error"
                style={{transform:"scale(0.9)"}}
              />{" "}
              <div className="EventBookCityNameHeading">
                <img src={line} className="EventBookCityNameLine" alt=" " />
                <div className="EventBookCityName">Guwahati</div>
                <img src={line} className="EventBookCityNameLine" alt=" " />
              </div>
            </div>
          </div>
          <div>
            <EventContent
              numEvents={12}
              pgn={10}
              EventClicking={props.EventClicking}
              city={"Guwahati"}
            />
          </div>
        </Page>
        <Page number="11" img2={props.img2}
          img3={props.img3}
          img4={props.img4}
          img6={props.img6}
          img7={props.img7}>
          <div>
            <EventContent2
              city={"Guwahati"}
              numEvents={11}
              EventClicking={props.EventClicking}
              pgn={11}
            />
          </div>
        </Page>

          {/* <Page
            number="12"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img src={Hyderabad} alt="error" />{" "}
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Hyderabad</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Hyderabad"}
                pgn={12}
              />
            </div>
          </Page>
          <Page
            number="13"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Hyderabad"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={13}
              />
            </div>
          </Page> */}
          {/* <Page
            number="14"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Indore}
                  className="EventBookCityNameImage"
                  alt="error"
                  style={{ transform: "translateX(15px)" }}
                />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Indore</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Indore"}
                pgn={14}
              />
            </div>
          </Page>
          <Page
            number="15"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Indore"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={15}
              />
            </div>
          </Page> */}
          {/* <Page
            number="16"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Jaipur}
                  alt="error"
                  className="EventBookCityNameImage"
                />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Jaipur</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Jaipur"}
                pgn={16}
              />
            </div>
          </Page>
          <Page
            number="17"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Jaipur"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={17}
              />
            </div>
          </Page>

          <Page
            number="18"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Lucknow}
                  className="EventBookCityNameImage"
                  alt="error"
                />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Lucknow</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                pgn={18}
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Lucknow"}
              />
            </div>
          </Page>
          <Page
            number="19"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Lucknow"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={19}
              />
            </div>
          </Page>

          <Page
            number="20"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Mumbai}
                  className="EventBookCityNameImage"
                  alt="error"
                />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Mumbai</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                pgn={20}
                EventClicking={props.EventClicking}
                city={"Mumbai"}
              />
            </div>
          </Page>
          <Page number="21">
            <div>
              <EventContent2
                city={"Mumbai"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={21}
              />
            </div>
          </Page>

          <Page
            number="22"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img src={Nagpur} alt="error" />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Nagpur</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                pgn={22}
                EventClicking={props.EventClicking}
                city={"Nagpur"}
              />
            </div>
          </Page>
          <Page
            number="23"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Nagpur"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={23}
              />
            </div>
          </Page> */}

          <Page
            number="12"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img src={Patna} alt="error" />
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Patna</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Patna"}
                pgn={12}
              />
            </div>
          </Page>
          <Page
            number="13"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Patna"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={13}
              />
            </div>
          </Page>

          {/* <Page
            number="24"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Pune}
                  className="EventBookCityNameImage"
                  alt="error"
                />{" "}
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Pune</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Pune"}
                pgn={24}
              />
            </div>
          </Page>
          <Page
            number="25"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Pune"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={25}
              />
            </div>
          </Page>

          <Page
            number="26"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Vishakapatnam}
                  alt="error"
                  style={{ transform: "translateX(23px)" }}
                />{" "}
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Visakhapatnam</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={12}
                EventClicking={props.EventClicking}
                city={"Vishakapatnam"}
                pgn={26}
              />
            </div>
          </Page>
          <Page
            number="27"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Vishakapatnam"}
                numEvents={12}
                EventClicking={props.EventClicking}
                pgn={27}
              />
            </div>
          </Page> */}

          <Page
            number="14"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div className="EventCityIcon iconcity">
              <div className="iconCityName">
                <img
                  src={Raipur}
                  className="EventBookCityNameImage"
                  alt="error"
                />{" "}
                <div className="EventBookCityNameHeading">
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                  <div className="EventBookCityName">Raipur</div>
                  <img src={line} className="EventBookCityNameLine" alt=" " />
                </div>
              </div>
            </div>
            <div>
              <EventContent
                numEvents={11}
                EventClicking={props.EventClicking}
                city={"Raipur"}
                pgn={14}
              />
            </div>
          </Page>
          <Page
            number="15"
            img2={props.img2}
            img3={props.img3}
            img4={props.img4}
            img6={props.img6}
            img7={props.img7}
          >
            <div>
              <EventContent2
                city={"Raipur"}
                numEvents={11}
                EventClicking={props.EventClicking}
                pgn={15}
              />
            </div>
          </Page> 
        </HTMLFlipBook>

        <div className="bookmarks-button">
          <button
            className={
              cntrl === 1
                ? "event-book-link-gallery event-book-link-gallery-hover"
                : "event-book-link-gallery"
            }
            // className="event-book-link-gallery"
            onClick={() => {
              book.current.pageFlip().flip(2);
            }}
          >
            <div className="toUppercase">Cities</div>
          </button>
          {/* <button
            className={
              cntrl === 3
                ? "event-book-link-index event-book-link-gallery-hover"
                : "event-book-link-index"
            }
            // className="event-book-link-index"
            onClick={() => {
              book.current.pageFlip().flip(4);
            }}
          >
            <div className="toUppercase">Cities</div>
          </button> */}
        </div>
      </div>
    </>
  );
}
export default MyBook;
