import React from "react";

const Footer = () => {
  const contactcss = `.cls-1{fill:#fff;opacity:0.8;}`;
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-8 mx-auto flex items-center md:flex-row flex-col">
          <div className="flex mx-auto items-center flex-shrink-0 space-x-4">
            <a
              href="https://www.facebook.com/springfest.iitkgp"
              target="_blank"
              className="inline-flex py-3 px-3  hover:scale-[1.3] transition-all ease-in duration-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={"45px"}
                width={"45px"}
                viewBox="0 0 35.38 35.38"
              >
                <defs>
                  <style>{contactcss}</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M35.38,17.69A17.69,17.69,0,1,0,14.93,35.17V22.8H10.44V17.69h4.49v-3.9c0-4.43,2.64-6.88,6.68-6.88a26.79,26.79,0,0,1,4,.35v4.35H23.34a2.55,2.55,0,0,0-2.88,2.76v3.32h4.9l-.78,5.11H20.46V35.17A17.7,17.7,0,0,0,35.38,17.69Z" />
                    <path
                      className="cls-1"
                      d="M24.58,22.8l.78-5.11h-4.9V14.37a2.55,2.55,0,0,1,2.88-2.76h2.23V7.26a26.79,26.79,0,0,0-4-.35c-4,0-6.68,2.45-6.68,6.88v3.9H10.44V22.8h4.49V35.17a18.31,18.31,0,0,0,5.53,0V22.8Z"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/iitkgp.springfest/?hl=en"
              target="_blank"
              className="inline-flex py-3 px-3  hover:scale-[1.3] transition-all ease-in duration-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={"45px"}
                width={"45px"}
                viewBox="0 0 35.38 35.38"
              >
                <defs>
                  <style>{contactcss}</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M35.38,17.69A17.69,17.69,0,1,1,17.69,0,17.69,17.69,0,0,1,35.38,17.69Z" />
                    <path
                      className="cls-1"
                      d="M23.18,11a1.22,1.22,0,1,0,1.22,1.21A1.21,1.21,0,0,0,23.18,11Z"
                    />
                    <path
                      className="cls-1"
                      d="M17.78,20.94a3.28,3.28,0,1,1,3.27-3.27,3.28,3.28,0,0,1-3.27,3.27Zm0-8.38a5.11,5.11,0,1,0,5.11,5.11,5.11,5.11,0,0,0-5.11-5.11Z"
                    />
                    <path
                      className="cls-1"
                      d="M13.55,9.25a4.28,4.28,0,0,0-4.28,4.28v8.28a4.28,4.28,0,0,0,4.28,4.28h8.28a4.28,4.28,0,0,0,4.28-4.28V13.53a4.28,4.28,0,0,0-4.28-4.28ZM21.83,28H13.55a6.24,6.24,0,0,1-6.23-6.23V13.53A6.24,6.24,0,0,1,13.55,7.3h8.28a6.23,6.23,0,0,1,6.23,6.23v8.28A6.24,6.24,0,0,1,21.83,28Z"
                    />
                  </g>
                </g>
              </svg>{" "}
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/sfiitkgp/"
              className="inline-flex py-3 px-3 hover:scale-[1.3] transition-all ease-in duration-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={"45px"}
                width={"45px"}
                viewBox="0 0 65.96 65.96"
              >
                <defs>
                  <style>{contactcss}</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_6" data-name="Layer 6">
                    <circle className="cls-1" cx="33.23" cy="32.68" r="31.55" />
                    <path d="M33,0A33,33,0,1,0,66,33,33,33,0,0,0,33,0Zm-10,47.32H17.21V25.81h5.73ZM20.07,21.51a2.87,2.87,0,1,1,2.87-2.87A2.86,2.86,0,0,1,20.07,21.51ZM50.19,47.32H44.45V36.56a5,5,0,0,0-10,0V47.32H28.68V25.81h5.73v2.6a9.32,9.32,0,0,1,15.78,6.72Z" />
                  </g>
                </g>
              </svg>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/springfest_kgp"
              className=" inline-flex py-3 px-3 hover:scale-[1.3] transition-all ease-in duration-500 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height={"45px"}
                width={"45px"}
                viewBox="0 0 24 24"
                version="1.1"
                className="bg-black border-2 border-black rounded-full"
              >
                <defs>
                  <style>{contactcss}</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1 "
                      d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
