import React, { useState } from "react";
// import 'landing.css';
import "./landingpage.css";
import signin from "./images/sign in.png";
import signout from "./images/signout.png";
import events from "./images/events.png";
import profile from "./images/profile.png";
import contactus from "./images/contact us.png";
// import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Modal from "../../Modal";
import back from "../../Images/back.png";
// import Navbar from "../Navbar/Navbar";
// import video from "./animation_final_sound.webm"

import video from "./vid.mp4"
import mobVideo from "./vidmob.mp4"

import bb from "./images/bbbg.png"
function Landingpage(props) {
  const {
    setShowBack,
    showBack,
    // setlogoutdecision,
    // logout_decision,
    // setModalLandingPage,
    // ModalLandingPage,
    setStatus,
    status,
    // change_logout_decision,
    // toggleBackClickPgeTurn,
    // showModalLandingPage,
    log,
  } = props;
  let navigate = useNavigate();
  // let backGO=false;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [backgroundVideo,setBackgroundVideo]=useState(video);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setScreenWidth(currentWidth);
      if (currentWidth < 500) {
        setBackgroundVideo(mobVideo); 
      } else {
        setBackgroundVideo(video); 
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);



  const moveMob = () => {
    document.getElementById("button-container-container").style.display =
      "block";
    document.getElementById("button-container").style.display = "grid";
    setTimeout(() => {
      document.getElementById("button-container").style.left = "0";
      document.getElementById("button-container").style.right = "0";
      // document.querySelectorAll(".hitch-hike")[0].style.opacity = 0;
      // document.querySelectorAll(".hitch-hike")[0].style.scale = "0";
      document.querySelectorAll(".butto1mob")[0].style.display = "none";
      // document.querySelectorAll(".butto1")[0].style.zIndex = 11;

      // console.log(document.querySelectorAll(".butto"));
      document.querySelectorAll(".butto").forEach((ele) => {
        ele.style.opacity = 1;
      });
    }, 10);
    document.getElementsByClassName(
      "landing-page"
    )[0].style.backgroundImage = `url(${bb})`;
  };
  const animation = () => {
    document.getElementById("video").style.zIndex = 10;
    document.getElementById("video").currentTime = 0;
    console.log("ani", document.getElementById("video").currentTime);
    document.getElementById("video").play();
    document.getElementById("video").style.opacity = 1;
    document.getElementById("video").volume = 0.2;
    setTimeout(() => {
      document.getElementById("button-container-container").style.display =
        "block";
      document.getElementById("button-container").style.display = "grid";
      setTimeout(() => {
        document.getElementById("button-container").style.left = "0";
        document.getElementById("button-container").style.right = "0";
        // document.querySelectorAll(".hitch-hike")[0].style.opacity = 0;
        // document.querySelectorAll(".hitch-hike")[0].style.scale = "0";
        document.querySelectorAll(".butto1")[0].style.display = "none";
        // document.querySelectorAll(".butto1")[0].style.zIndex = 11;
        document.getElementsByClassName(
          "landing-page"
        )[0].style.backgroundImage = `url(${bb})`;
        console.log(document.querySelectorAll(".butto"));
        document.querySelectorAll(".butto").forEach((ele) => {
          ele.style.opacity = 1;
        });
      }, 10);
    }, 1900);
    setTimeout(() => {
      document.getElementById("video").style.opacity = 0;
    }, 2900);
    setTimeout(() => {
      document.getElementById("video").style.zIndex = -1000000;
    }, 2900);
  };
  const animationrev = () => {
    document.getElementById("video").style.zIndex = 12;
    document.getElementById("video").currentTime = 0;
    console.log("rev", document.getElementById("video").currentTime);

    document.getElementById("video").play();
    document.getElementById("video").style.opacity = 1;
    // console.log("HI")
    document.querySelector(
      ".landing-page"
    ).style.backgroundImage = `url(${bb})`;
    setTimeout(() => {
      document.getElementById("button-container").style.left = "0%";
      document.getElementById("button-container").style.right = "0%";
      // document.querySelectorAll(".hitch-hike")[0].style.scale = "1";
      // document.querySelectorAll(".hitch-hike")[0].style.opacity = 1;
      // document.querySelectorAll(".butto1")[0].style.scale = "1";
      document.querySelectorAll(".butto1")[0].style.display = "block";
      console.log(document.querySelectorAll(".butto"));
      document.querySelectorAll(".butto").forEach((ele) => {
        ele.style.opacity = 0;
      });
    }, 600);

    setTimeout(() => {
      document.getElementById("video").style.opacity = 0;
    }, 2300);
    setTimeout(() => {
      document.getElementById("video").style.zIndex = -1000000;
      document.getElementById("button-container").style.display = "none";
      document.getElementById("button-container-container").style.display =
        "none";
    }, 2900);
  };
  const animationrevMob = () => {
    document.getElementById("button-container").style.left = "0%";
    document.getElementById("button-container").style.right = "0%";
    // document.querySelectorAll(".hitch-hike")[0].style.scale = "1";
    // document.querySelectorAll(".hitch-hike")[0].style.opacity = 1;
    // document.querySelectorAll(".butto1")[0].style.scale = "1";
    document.querySelectorAll(".butto1mob")[0].style.display = "block";

    // console.log(document.querySelectorAll(".butto"));
    document.querySelectorAll(".butto").forEach((ele) => {
      ele.style.opacity = 0;
    });
    document.getElementById("button-container").style.display = "none";
    document.getElementById("button-container-container").style.display =
      "none";
    document.getElementsByClassName(
      "landing-page"
    )[0].style.backgroundImage = `url(${bb})`;
  };

  useEffect(() => {
    if (props.backclicked) {
      setTimeout(() => {
        animation();
        props.toggleBackClickPgeTurn();
        setShowBack(true);
      }, 1);
    }
    // document.getElementsByClassName("landing-page")[0].style.backgroundImage = `url(${bg})`;
  }, []);

  setTimeout(() => {
    if (localStorage.getItem("data")) {
      setStatus("Logout");
    } else {
      setStatus("Sign In");
    }
  }, 100);
  
  // document.getElementById("video").volume = 0.2;
  return (
    <div className="landing-container">
      <video preload id="video" className="w-full h-full landingpage-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      {showBack && (
        <>
          <div
            className="back"
            onClick={() => {
              animationrev();
            }}
          >
            <button
              className="BackButtonLandingPage"
              onClick={() => {
                animationrev();
                setShowBack(!showBack);
              }}
            >
              <img src={back} alt="" />
            </button>
          </div>
          <div
            className="backMob"
            onClick={() => {
              animationrevMob();
            }}
          >
            <button
              className="BackButtonLandingPage"
              onClick={() => {
                animationrevMob();
                setShowBack(!showBack);
              }}
            >
              <img src={back} alt="" />
            </button>
          </div>
        </>
      )}
      <div className="landing-page">
        {/* <img className="hitch-hike" src={bg3} alt="" /> */}
        <div
          className="butto1"
          onClick={() => {
            animation();
            setShowBack(!showBack);
          }}
        >
          <div className=" cloud-name-enter">Enter</div>
        </div>
        <div
          className="butto1mob"
          onClick={() => {
            moveMob();
            setShowBack(!showBack);
          }}
        >
          <div className=" cloud-name-enter">Enter</div>
        </div>
      </div>
      <div className="landing-button-container" id="button-container-container">
        <div className="landing-button" id="button-container">
          <div className="button-1 butto">
            <Link to="/events" className="landingPageButtonHover">
              <img src={events} className="cloud-name" />
              {/* <img className="cloud-img cloud-img-1" src={cloud} alt="" /> */}
            </Link>
          </div>
          <div className="button-2 butto">
            <div
              onClick={() => {
                // props.setLoginSignup();
                log();
              }}
              className="landingPageButtonHover"
            >
              <img
                src={localStorage.getItem("data") ? signout : signin}
                className="cloud-name"
              />

              {/* <img className="cloud-img cloud-img-1" src={cloud} alt="" /> */}
            </div>
          </div>
          <div className="button-3 butto">
            <a className="landingPageButtonHover">
              <img
                src={profile}
                className="cloud-name"
                onClick={() => {
                  if (status == "Logout") {
                    navigate("/profile");
                  } else {
                    props.showToast(
                      true,
                      "Please Login First",
                      "Unauthorized Access"
                    );
                  }
                }}
              />
            </a>
            {/* <img className="cloud-img cloud-img-1" src={cloud} alt="" 
              onClick={() => {
                if (status == "Logout") {
                  navigate("/profile");
                } else {
                  props.showToast(
                    true,
                    "You are not logged in",
                    "Unsuccessful"
              );}}}
            /> */}
          </div>
          <div className="button-4 butto">
            <Link to={"/contact"} className="landingPageButtonHover">
              <img src={contactus} className="cloud-name" />
              {/* <img className="cloud-img cloud-img-1" src={cloud} alt="" /> */}
            </Link>
          </div>

          {/* <div className="button-5 butto">
            <Link to={"/MobileGallery"} className="landingPageButtonHover">
              <h1 className="cloud-name">Gallery</h1> */}
          {/* <img className="cloud-img cloud-img-1" src={cloud} alt="" /> */}
          {/* </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Landingpage;
