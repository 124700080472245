import "./Book1.css";
import { useLocation, useNavigate } from "react-router-dom";
import icons from "./cityevent.json";
import { Link } from "react-router-dom";
import BackButton from "../BackButton/BackButton";
import { useEffect } from "react";

const MobileEvent = (props) => {
  const location = useLocation();
  let i = 1;
  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth > 929) {
      navigate("/events");
    }
  }, []);
  return (
    <div className="bg">
      <BackButton />
      <div className="mobbg">
        <div id="" className="">
          {icons.cities.map((icon) => {
            if (location.state.cityname === icon.city)
              return (
                <div className="Mobile-grid-container" key={icon.id}>
                  <div className="cityheadermob">{icon.city}</div>
                  {icon.events.map((eve) => {
                    return (
                      <Link
                        to={`/EventDashboard/${location.state.cityname}/${eve
                          .split(" ")
                          .join("_")}`}
                        onClick={() => {
                          props.EventClicking(
                            `${eve}`,
                            `${location.state.cityname}`
                          );
                        }}
                      >
                        <div
                          className={`Mobile-eventsicons MobileEventsicon-${i} MobileEventsicon-${eve}`}
                          key={eve}
                        >
                          <div id="event-image-mob">
                            <img
                              className="mobile-icons"
                              src={require(`./iconsevents/${eve}.png`)}
                              alt={`${eve}`}
                            />
                          </div>
                          <div className="eventname-mob">{eve}</div>
                          <div style={{ display: "none" }}>{i++}</div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
};
export default MobileEvent;
