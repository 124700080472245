import React from "react";
// import Credits from "../Credits/Credits";
import Footer from "../Footer/Footer";
import ContactCard from "./ContactCard/ContactCard";
import "./contact.css";
import BackButton from "../BackButton/BackButton";
const ContactUs = (props) => {
  const conctactstyle=`
  .contact-background{

    background: url(${props.contactbackground.src}) repeat fixed 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: 'Cinzel Decorative', cursive;
}
  `
  return (
    <div className="contact-background text-white p-1  text-center ">
      <style>{conctactstyle}</style>
      <div>
        <BackButton togglebackclick={props.toggleBackClickPgeTurn}/>
        <div className="container flex justify-center mx-auto mt-[5rem]">
          <div>
            <h1 className="sm:text-5xl text-3xl  font-bold pb-6 mx-auto">
              Contact Team
            </h1>
          </div>
        </div>
        <div className="w-full px-10 pt-10">
          <div className="container mx-auto">
            <div className="lg:flex md:flex sm:flex items-center justify-center xl:justify-around flex-wrap md:justify-around sm:justify-around lg:justify-around p-1">


                <ContactCard
                  number={8806091460}
                  img={props.cimg1}
                  name={"Ankush"}
                  lastname={"Kukde"}
                  facebook={
                    "https://www.facebook.com/dada.ram.1485?mibextid=ZbWKwL"
                  }
                  linkedin={
                    "https://www.linkedin.com/in/ankush-kukde-420b4825a"
                  }
                  mail={"mailto: ankushkukde789@gmail.com"}
                />
                <ContactCard
                number={7303549462}
                img={props.cimg2}
                name={"Uttkarsh"}
                lastname={"Kumar"}
                facebook={""}
                linkedin={"https://www.linkedin.com/in/uttkarsh-kumar?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"}
                mail={"mailto: uttkarsh.iitkgp@gmail.com"}
              />
              <ContactCard
                number={9753034242}
                img={props.cimg3}
                name={"Anurag"}
                lastname={"Dangi"}
                facebook={
                  "https://www.facebook.com/profile.php?id=100090927442713"
                }
                linkedin={
                  "https://www.linkedin.com/in/anurag-dangi-5a7b37270"
                }
                mail={"mailto: anuragdangiad498@gmail.com"}
              /><ContactCard
              number={9702501782}
              img={props.cimg4}
              name={"Sagar"}
              lastname={"Tungariya"}
              facebook={"https://www.facebook.com/sagar.tungariya.5?mibextid=ZbWKwL"}
              linkedin={"https://www.linkedin.com/in/sagardaocean?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"}
              mail={"mailto: sagartungariya.iitkgp@gmail.com"}
            />
            </div>
          </div>
        </div>
      </div>
      {/* <Credits /> */}
      <Footer />
    </div>
  );
};

export default ContactUs;
