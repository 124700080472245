import React from "react";
import "./Book1.css";
import { useNavigate } from "react-router-dom";
import icons from "./cityevent.json";
import BackButton from "../BackButton/BackButton";
// import MobileEvent from './MobileEvent';
const Book1 = (props) => {
  var cityname;
  const navigate = useNavigate();
  // const cities = ["Ahmedabad", "Bangalore", "Bhubaneshwar", "Chandigarh", "Chennai", "Delhi", "Guwahati", "Hyderabad", "Jaipur", "Kolkata", "Lucknow", "Mumbai", "Nagpur", "Patna", "Pune", "Raipur", "Ranchi"];
  const handleClassAddition = (cityna) => {
    var clickedCity = { cityname: `${cityna}` };
      navigate("/MobileEvent", { state: clickedCity });
  };

  return (
    <div className="bg">
      <BackButton togglebackclick={props.togglebackClickPage} />
      <div className="mobbg">
        <div id="">
          <div className="Mobile-grid-container">
            <div className="MobileHeaderCity">
              <h1>CITIES</h1>
            </div>
            <div className="MobileCitycontainer">
              {icons.cities.map((ic) => {
                return (
                  <div className="Mobile-grid" id="griditemsmob" key={ic.id}>
                    <div
                      className="Mobile-cityname"
                      id="Mobilecitynames"
                      onClick={() => {
                        cityname = ic.city;
                        handleClassAddition(cityname);
                      }}
                    >
                      {ic.city}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Book1;
